const CollectionFragment = `
  id
  databaseId
  title
  slug
  dateGmt
  acfLightbox {
    items {
      boardType
      boardSlug
      modelSlug
      section
      images {
        imageId
        orientation
        url
      }
    }
  }
`

export default CollectionFragment

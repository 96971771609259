import { useState, useContext } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade'

import { SitesContext } from 'context/SitesContextProvider'

import CollectionsButton from 'components/CollectionsButton'

import s from './ModelMeasurements.module.css'

function ModelMeasurements({
  measurements,
  className,
  listClassName,
  itemClassName,
  valueClassName,
  shoesToggle,
  noToggle,
  lightStyle,
  male,
  fadeDuration = 500,
  units: initialUnits,
  modelProps,
  mobile,
}) {
  const { currentSite } = useContext(SitesContext)
  const router = useRouter()

  const [units, setUnits] = useState(
    initialUnits === 'cm'
      ? 'metric'
      : initialUnits || currentSite?.units || 'imperial'
  )

  if (!measurements || router.query?.boardType === 'talent') return null

  const measurementValue = ({
    fieldName,
    fieldValue,
    fieldValueHuman: { metric, us, uk },
  }) => {
    // This is all a bit of a mess, but here's the thing, there are many wants in which
    // measurements come through, for example...

    // If there are no values for metric, us and uk, then the value is a plain string, like hair colour
    if (us === null && uk === null && metric === null) {
      return fieldValue
    }

    // Otherwise, if us has a ' or " in it, this is inches and the metric needs CM added
    if (us.search(/['"]/) >= 0) {
      return `${metric}cm / ${us.replace(/\.0"$/, '"')}`
    }

    // Now here comes the fun bit, if all of them are numbers and UK exists, then it's shoe sizes
    if (!isNaN(us) && !isNaN(uk) && !isNaN(metric) && uk !== null) {
      return `${metric} / ${us} / ${uk}`
    }

    // And finally we get down to the annoying edge case. We have shoe size and things like 'neck' where
    // both the metric and us are just numbers, so we need to differentiate by the field name at this point
    if (fieldName.search(/shoe/i) >= 0 || fieldName === 'Size EU/US') {
      return `${metric} / ${us}`
    }

    // And if we get here, we can assume that we have cm and inches but that both don't have their units
    return `${metric}cm / ${us}"`
  }

  const measurementLabel = ({ fieldName }) => {
    if (male && fieldName === 'Bust') return 'Chest'
    return fieldName
  }

  const measurementsToDisplay = measurements.filter(
    ({ fieldName, fieldValue, humanValue }) => {
      if (fieldName === 'Location') return false
      return fieldValue !== null && fieldName !== 'Hair length'
    }
  )

  if (measurementsToDisplay.length === 0) return null

  const setShoeSizeFilter = (shoeSizes) => {
    if (!shoeSizes) return null
    const { us, uk, metric } = shoeSizes?.fieldValueHuman
    return (
      <div className={s.shoeSizesWrapper}>
        <select
          name={mobile ? 'shoeSizesMobile' : 'shoeSizes'}
          id={mobile ? 'shoeSizesMobile' : 'shoeSizes'}
          defaultValue={currentSite?.name === 'London' ? 'uk' : units}
          className={classNames(s.value, valueClassName)}
        >
          {us && <option value="us">US {us}</option>}
          {uk && <option value="uk">UK {uk}</option>}
          {metric && <option value="metric">EU {metric}</option>}
        </select>
      </div>
    )
  }

  return (
    <div className={classNames(className, { [s.lightStyle]: lightStyle })}>
      {!mobile && !noToggle && <CollectionsButton modelProps={modelProps} />}
      <Fade bottom cascade ssrReveal duration={fadeDuration}>
        <div>
          <ul className={classNames(s.list, listClassName)}>
            {measurementsToDisplay.map((measurement) => {
              if (!measurement.fieldValue || measurement.fieldValue === '0')
                return null
              return (
                <li
                  key={measurement.fieldName}
                  className={classNames(s.item, itemClassName)}
                >
                  {measurementLabel(measurement)}

                  {measurementValue(measurement) &&
                    (measurement.fieldName === 'Shoes EU/US/UK' && shoesToggle ? (
                      setShoeSizeFilter(measurement)
                    ) : (
                      <span className={classNames(s.value, valueClassName)}>
                        {measurementValue(measurement)}
                      </span>
                    ))}
                </li>
              )
            })}
          </ul>
        </div>
      </Fade>
    </div>
  )
}

ModelMeasurements.propTypes = {
  measurements: PropTypes.array,
  className: PropTypes.string,
  listClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  noToggle: PropTypes.bool,
  shoesToggle: PropTypes.bool,
  lightStyle: PropTypes.bool,
  male: PropTypes.bool,
  fadeDuration: PropTypes.number,
  units: PropTypes.string,
  mobile: PropTypes.bool,
}
ModelMeasurements.defaultProps = {
  measurements: [],
  className: '',
  listClassName: '',
  itemClassName: '',
  valueClassName: '',
  noToggle: false,
  shoesToggle: false,
  lightStyle: false,
  male: false,
  fadeDuration: 500,
  units: '',
  mobile: false,
}

export default ModelMeasurements

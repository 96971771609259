import { gql } from "@apollo/client";

const COLLECTIONS_CREATE_QUERY = gql`
  mutation COLLECTIONS_CREATE_QUERY($input: CreateLightboxInput!) {
    createLightbox(input: $input) {
      lightbox {
        title
      }
    }
  }
`

export default COLLECTIONS_CREATE_QUERY

const allowedAttributes = ['collections', 'account']

const isLocalStorageAvailable = function () {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const localStorageAvailable = isLocalStorageAvailable()

export function store(name, value) {
  if (!localStorageAvailable) return false
  if (!allowedAttributes.includes(name)) return false

  try {
    localStorage.setItem(name, JSON.stringify(value))
    return true
  } catch (e) {
    return false
  }
}

export default function fetch(name, defaultValue = null) {
  if (!localStorageAvailable) return defaultValue
  if (!allowedAttributes.includes(name)) return defaultValue

  const value = localStorage.getItem(name)
  if (value !== null) return JSON.parse(value)
  return defaultValue
}

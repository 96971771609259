import { gql } from "@apollo/client";

const VALIDATE_USER_QUERY = gql`
  mutation VALIDATE_USER_QUERY($input: RefreshJwtAuthTokenInput!) {
    refreshJwtAuthToken(input: $input) {
      authToken
    }
  }
`

export default VALIDATE_USER_QUERY

import { gql } from "@apollo/client";

import collectionFragment from 'graphql/fragments/collection.query'

const COLLECTIONS_UPDATE_QUERY = gql`
  mutation COLLECTIONS_UPDATE_QUERY($input: UpdateLightboxInput!) {
    updateLightbox(input: $input) {
      lightbox {
        ${collectionFragment}
      }
    }
  }
`

export default COLLECTIONS_UPDATE_QUERY

import graphqlQuery from 'helpers/graphqlQuery'

import COLLECTIONS_UPDATE_QUERY from 'graphql/mutations/collectionsUpdate.query'
import {
  COLLECTIONS_UPDATE_STARTED,
  COLLECTIONS_UPDATE_FAILED,
  COLLECTIONS_UPDATE_SUCCESS,
} from '@constants'

function started() {
  return { type: COLLECTIONS_UPDATE_STARTED, payload: {} }
}

function failed(error) {
  return { type: COLLECTIONS_UPDATE_FAILED, payload: error }
}

function success(collection) {
  return {
    type: COLLECTIONS_UPDATE_SUCCESS,
    payload: { collection: { ...collection, name: collection.title } },
  }
}

export default function action(
  authToken,
  id,
  { items = null, newItems = null, title = null }
) {
  return (dispatch) => {
    dispatch(started())
    return graphqlQuery(
      COLLECTIONS_UPDATE_QUERY,
      'WP_ROOT',
      {
        input: {
          clientMutationId: 'CollectionUpdate',
          id,
          title,
          newItems,
          items,
        },
      },
      authToken
    ).then((resp) => {
      if (resp.data && resp.data.updateLightbox)
        return dispatch(success(resp.data.updateLightbox.lightbox))

      return dispatch(failed(resp.error || resp.data))
    })
  }
}

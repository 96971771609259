import { gql } from "@apollo/client";

import collectionFragment from 'graphql/fragments/collection.query'

const COLLECTIONS_QUERY = gql`
  query COLLECTIONS_QUERY($authorName: String!) {
    lightboxes(where: { authorName: $authorName }) {
      nodes {
        ${collectionFragment}
      }
    }
  }
`

export default COLLECTIONS_QUERY

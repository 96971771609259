import { useState, useContext, useEffect } from 'react'
import classNames from 'classnames'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'context/GlobalContextProvider'
import loadCollections from 'actions/collections/load'
import createCollection from 'actions/collections/create'
import updateCollection from 'actions/collections/update'
import loadAccount from 'actions/account/load'

import Link from 'components/Link'

import s from './CollectionsButton.module.css'

//TODO: Setup proptypes
function CollectionsButton({ modelProps }) {
  const dispatch = useContext(GlobalDispatchContext)
  const { collections, account } = useContext(GlobalStateContext)
  const [display, setDisplay] = useState(null)
  const [search, setSearch] = useState('')
  const [newCollectionName, setNewCollectionName] = useState('')

  useEffect(() => {
    if (!account.loggedIn) dispatch(loadAccount())
  }, [])

  useEffect(() => {
    if (account.loggedIn && !collections.loaded)
      dispatch(loadCollections(account.authToken, account.user.name))
  }, [account])

  useEffect(() => {
    if (newCollectionName && collections.items) {
      const matching = collections.items.find(
        (c) => c.name === newCollectionName
      )
      if (matching) {
        setNewCollectionName('')
        setDisplay('list')
      }
    }
  }, [collections])

  if (process.env.NEXT_PUBLIC_DISABLE_COLLECTIONS_FEATURE) return null

  const handleCreateCollection = (e) => {
    e.preventDefault()
    dispatch(
      createCollection(account.authToken, newCollectionName, [
        {
          boardSlug: modelProps.boardSlug,
          boardType: modelProps.boardType,
          modelSlug: modelProps.modelSlug,
          section: 'portfolio', // todo: may need to not hard-code this
          images: modelProps.portfolio.map((p) => ({
            id: p.id,
            url: p.sourceUrl,
            orientation: p.orientation,
          })),
        },
      ])
    )
  }

  const handleAddToCollection = (collection) => {
    dispatch(
      updateCollection(account.authToken, collection.id, {
        items: [
          {
            boardSlug: modelProps.boardSlug,
            boardType: modelProps.boardType,
            modelSlug: modelProps.modelSlug,
            section: 'portfolio', // todo: may need to not hard-code this
            images: modelProps.portfolio.map((p) => ({
              id: p.id,
              url: p.sourceUrl,
              orientation: p.orientation.toString(),
            })),
          },
        ],
      })
    )
  }

  const handleRemoveFromCollection = (collection) => {
    dispatch(
      updateCollection(account.authToken, collection.id, {
        items: [
          {
            boardSlug: modelProps.boardSlug,
            boardType: modelProps.boardType,
            modelSlug: modelProps.modelSlug,
            section: 'portfolio', // todo: may need to not hard-code this
            action: 'delete',
          },
        ],
      })
    )
  }

  if (collections.isLoading)
    return (
      <div className={s.container}>
        <button type="button" disabled className={s.button}>
          Loading...
        </button>
      </div>
    )

  // todo: some repition could be removed, possibly by extracting these into their own components
  if (display === 'new') {
    return (
      <div className={s.container}>
        <div className={s.content}>
          {account.loggedIn ? (
            <form onSubmit={handleCreateCollection}>
              <h2 className={s.title}>Create a new collection</h2>
              {collections.error && (
                <p className={s.error}>{collections.error}</p>
              )}
              <input
                type="text"
                value={newCollectionName}
                placeholder="Collection name"
                onChange={(e) => setNewCollectionName(e.target.value)}
                className={s.input}
              />
              <br />
              <br />
              <input type="submit" value="Create" className={s.button} />
            </form>
          ) : (
            <>
              To create more than 1 lightbox you will need to login
              <Link href="/collections/login" type="Collections">
                Login
              </Link>
            </>
          )}
        </div>
        <button
          type="button"
          onClick={() => setDisplay(null)}
          className={s.button}
        >
          Close
        </button>
      </div>
    )
  }

  if (display === 'list') {
    let results = [...collections.items]

    if (search !== '')
      results = results.filter((r) =>
        r.name.toLowerCase().includes(search.toLowerCase())
      )
    return (
      <div className={s.container}>
        {account.loggedIn ? (
          <div className={s.content}>
            <h2 className={s.title}>Add to a collection</h2>
            <input
              type="text"
              value={search}
              placeholder="Search collection by name..."
              onChange={(e) => setSearch(e.target.value)}
              className={s.input}
            />
            <div className={s.collections}>
              {results && results.length > 0
                ? results.map((r) => {
                    const modelAlreadyAdded =
                      r.acfLightbox &&
                      r.acfLightbox.items &&
                      r.acfLightbox.items.find(
                        (i) => i.modelSlug === modelProps.modelSlug
                      )
                    return (
                      <div className={s.collection} key={r.id}>
                        {r.name}{' '}
                        <button
                          type="button"
                          className={classNames(s.button, s.smallButton)}
                          onClick={() =>
                            modelAlreadyAdded
                              ? handleRemoveFromCollection(r)
                              : handleAddToCollection(r)
                          }
                        >
                          {modelAlreadyAdded ? 'Remove' : 'Add'}
                        </button>
                      </div>
                    )
                  })
                : 'No matching results'}
            </div>
          </div>
        ) : (
          <div className={s.content}>
            <Link href="collections/login" type="Collections">
              Please login to create collections
            </Link>
          </div>
        )}
        <button
          type="button"
          onClick={() => setDisplay(null)}
          className={s.button}
        >
          Close
        </button>
        {account.loggedIn && (
          <button
            type="button"
            onClick={() => setDisplay('new')}
            className={s.button}
          >
            New
          </button>
        )}
      </div>
    )
  }

  return (
    <button
      type="button"
      onClick={() => setDisplay('list')}
      className={classNames(s.container, s.button)}
    >
      + Collection
    </button>
  )
}

export default CollectionsButton

import graphqlQuery from 'helpers/graphqlQuery'

import COLLECTIONS_CREATE_QUERY from 'graphql/mutations/collectionsCreate.query'
import {
  COLLECTIONS_CREATE_STARTED,
  COLLECTIONS_CREATE_FAILED,
  COLLECTIONS_CREATE_SUCCESS,
} from '@constants'

function started() {
  return { type: COLLECTIONS_CREATE_STARTED, payload: {} }
}

function failed(error) {
  return { type: COLLECTIONS_CREATE_FAILED, payload: error }
}

function success(collection) {
  return {
    type: COLLECTIONS_CREATE_SUCCESS,
    payload: { collection: { ...collection, name: collection.title } },
  }
}

export default function action(authToken, name, items = null) {
  return (dispatch) => {
    dispatch(started())
    return graphqlQuery(
      COLLECTIONS_CREATE_QUERY,
      'WP_ROOT',
      {
        input: {
          clientMutationId: 'CollectionCreate',
          title: name,
          status: 'PUBLISH',
          items,
        },
      },
      authToken
    ).then((resp) => {
      if (resp.data && resp.data.createLightbox)
        return dispatch(success(resp.data.createLightbox.lightbox))

      return dispatch(failed(resp.error || resp.data))
    })
  }
}

import fetch, { store } from 'helpers/localStorage'
import graphqlQuery from 'helpers/graphqlQuery'

import VALIDATE_USER_QUERY from 'graphql/mutations/validateUser.query'
import {
  ACCOUNT_VALIDATION_STARTED,
  ACCOUNT_VALIDATION_FAILED,
  ACCOUNT_VALIDATION_SUCCESS,
} from '@constants'

function started() {
  return { type: ACCOUNT_VALIDATION_STARTED, payload: {} }
}

function failed(error) {
  store('account', {})
  return { type: ACCOUNT_VALIDATION_FAILED, payload: { error } }
}

function success(account, authToken) {
  const newAccount = { ...account, authToken }
  store('account', newAccount)
  return { type: ACCOUNT_VALIDATION_SUCCESS, payload: newAccount }
}

export default function action() {
  return (dispatch) => {
    dispatch(started())
    const account = fetch('account')
    if (!account) return dispatch(failed('User validation failed'))

    return graphqlQuery(VALIDATE_USER_QUERY, 'WP_ROOT', {
      input: {
        clientMutationId: 'RefreshAuthToken',
        jwtRefreshToken: account.refreshToken,
      },
    }).then((resp) => {
      console.log('ACCOUNT_VALIDATION_STARTED', resp.data)
      if (resp.data?.refreshJwtAuthToken.authToken)
        return dispatch(
          success(account, resp.data.refreshJwtAuthToken.authToken)
        )

      return dispatch(failed('User validation failed'))
    })
  }
}

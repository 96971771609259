function AbbreviatedNumber({ children: value }) {
  if (value < 1000) return value
  const suffixes = ['', 'K', 'M', 'B', ' T']

  if (`${value}`.length === 6)
    return value.toString().substr(0, 3) + suffixes[1]

  const suffixNum = Math.floor(`${value}`.length / 3)
  let shortValue = ''
  for (let precision = 2; precision >= 1; precision -= 1) {
    shortValue = parseFloat(
      (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(
        precision
      )
    )
    const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g, '')
    if (dotLessShortValue.length <= 2) {
      break
    }
  }
  if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)
  return shortValue + suffixes[suffixNum]
}

export default AbbreviatedNumber

import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './CloudinarySolarnetImage.module.css'

//TODO: You can refactor this so that we can pass breakpoints for images or I
// would suggest using the cloudinary-react sdk package that has been brought
// in towards the end of development due to having better out the box features i.e DPR.
function CloudinarySolarnetImage({ image, width, height, className }) {
  if (image.length < 1 || !image[0]?.url) return null

  const imgRef = useRef(null)
  const [inProp, setInProp] = useState(false)
  const { url, params, alt } = image[0]

  const setSrc = (setUrl, setParams) => {
    return `https://res.cloudinary.com/select-models/image/fetch/w_2560,c_limit${
      setParams ? `/${setParams}` : ''
    }/${setUrl}`
  }

  useEffect(() => {
    setInProp(true)
  }, [imgRef])

  return (
    <picture
      className={classNames(s.container, className, {
        [s.loaded]: inProp === true,
      })}
      onLoad={() => setInProp(true)}
    >
      {image[1]?.url && (
        <source
          srcSet={setSrc(image[1]?.url, image[1]?.params)}
          media="(min-width: 900px)"
        />
      )}
      <img
        ref={imgRef}
        width={width}
        height={height}
        src={setSrc(url, params)}
        alt={alt || ''}
        onLoad={() => setInProp(true)}
      />
    </picture>
  )
}

CloudinarySolarnetImage.propTypes = {
  image: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      params: PropTypes.string,
      breakpoint: PropTypes.string,
    })
  ),
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
}
CloudinarySolarnetImage.defaultProps = {
  image: [
    {
      url: '',
      alt: '',
      params: '',
      breakpoint: '',
    },
  ],
  width: '',
  height: '',
  className: '',
}

export default CloudinarySolarnetImage

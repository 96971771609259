import graphqlQuery from 'helpers/graphqlQuery'

import COLLECTIONS_QUERY from 'graphql/collections.query'
import {
  COLLECTIONS_LOAD_STARTED,
  COLLECTIONS_LOAD_FAILED,
  COLLECTIONS_LOAD_SUCCESS,
} from '@constants'

function started() {
  return { type: COLLECTIONS_LOAD_STARTED, payload: {} }
}

function failed(error) {
  return { type: COLLECTIONS_LOAD_FAILED, payload: error }
}

function success(collections) {
  return {
    type: COLLECTIONS_LOAD_SUCCESS,
    payload: {
      collections: collections.map((c) => ({
        ...c,
        name: c.title,
      })),
    },
  }
}

export default function action(authToken, authorName) {
  return (dispatch) => {
    dispatch(started())
    console.log('COLLECTIONS_QUERY', authToken)
    return graphqlQuery(
      COLLECTIONS_QUERY,
      'WP_ROOT',
      { authorName },
      authToken
    ).then((resp) => {
      if (resp.lightboxes) return dispatch(success(resp.lightboxes.nodes))

      return dispatch(failed(resp.error || resp.data))
    })
  }
}
